@tailwind base;
@tailwind components;
@tailwind utilities;

#root{
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100vw;
}

body{
    background-color: #f9fafb;
    overflow-x: hidden;
}

.font-roboto{
    font-family: 'Roboto Slab', serif;
}

.font-playfair{
    font-family: 'Playfair Display', serif;
}

.font-ubuntu{
    font-family: 'Ubuntu', sans-serif;
}

.box-card{
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    border-radius: 10px;
    /* border: 0.1px solid rgba(0, 0, 0, 0.1) */
}

.left-layout{
    transition: 300ms;
}

.right-layout{
    transition: 300ms;
}

.side-bar{
    transition: 300ms;
}

.active-tab{
    transition: 300ms;
}

.side-bar-tab{
    transition: 300ms;
}

.error-input{
    /* --tw-rign-color: red !important */
}

.active-tab{
    background-color: rgba(0, 0, 0, 0.3);
    /* border-left: 2px solid #fff; */
    border-radius: 5px;
    transition: 700ms;
}

.side-bar-tab:hover{
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    transition: 700ms;
}

.not_saw_notification {
    background-color: rgba(0,0,0,0.04)
}

.notification-count{
    position: absolute;
    background-color: red;
    color: white;
    font-size: 10px;
    padding: 1px;
    height: 16px;
    width: 16px;
    border-radius: 50px;
    top: 0px;
    right: 5px;
    font-weight: bold;
    text-align: center;
}

#important-info-container{
    /* width: 300px; */
    position: absolute;
    right: 0px;
    top: 0px;
    transition: 200ms;
    inset: 0;
    overscroll-behavior: contain;
}

#important-info-body-container{
    width: 300px;
    position: fixed;
    background-color: white;
    right: 0px;
    transition: 500ms;
}

.backdrop{
    background-color: rgba(0, 0, 0, 0.3);
    transition: 200ms;
}

.info-button-container{
    position: absolute;
    top: 0;
    left: 0;
}

#info-container-button{
    width: 30px;
    height: 30px;
    background-color: white;
    position: fixed;
    right: 300px;
    top: 50%;
    display: flex;
    transition: 500ms;
    float: right;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.open-info-section-button-container{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.open-info-section-button-container button{
    padding: 10px;
    /* background-color: #4F46E5; */
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
    color: whitesmoke;
}

.open-info-section-button-container button:hover{
    /* background-color: #6861f9; */
}

.side_bar_visible_button{
    position: absolute;
    right: -10px;
    top: 50%;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    z-index: 9;
}

#react-select-3-input {
    --tw-ring-color: none;
    --tw-ring-shadow: none
}

#react-select-2-input {
    --tw-ring-color: none;
    --tw-ring-shadow: none
}

.date-picker{
    border: 1px solid lightgrey;
    height: 35px;
    --tw-ring-color: rgba(15, 118, 110, 0.9) !important;
    /* --tw-ring-shadow: rgba(15, 118, 110, 0.9) !important */
}

.date-picker:active{
    border: 1px solid rgba(15, 118, 110, 0.9);
}

.date-picker:focus{
    border: 1px solid rgba(15, 118, 110, 0.9);
}

.filter-nofify{
    position: absolute;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background-color: red;
    top: 10px;
    right: 10px;
}

.shadow {
    -webkit-box-shadow: 3px 3px 5px 6px #ccc;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow:    3px 3px 5px 6px #ccc;  /* Firefox 3.5 - 3.6 */
    box-shadow:         0px 0px 5px 1px rgba(0, 0, 0, 0.0.7);  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
  }
.border{
    border: 0.2px solid #f3f2f2;
}

.border-light-grey{
    border: 1px solid #d3d3d3
}

.vehicle-card{
    transition: 500ms;
}

input:disabled {
    background: rgba(0, 0, 0, 0.03);
}